export function getProductPadding(width, screen) {

    switch (width * 10) {
        case 120:
            if(screen === 'weird'){
                return 6.5;
            }
            if(screen === 'small'){
                return 3
            }
            return 5;
        case 130:
            if(screen === 'weird'){
                return 6;
            }
            if(screen === 'small'){
                return 2.5
            }
            return 4;
        case 155:
            if(screen === 'weird'){
                return 5;
            }
            if(screen === 'small'){
                return 1.5
            }
            return 2.5;
        case 170:
        default:
            if(screen === 'weird'){
                return 4;
            }
            if(screen === 'small'){
                return 0.5;
            }
            return 1;
    }
}
