import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import propTypes from 'prop-types';
import {labelStyle} from '../../common.styles';
import ChevronIcon from '../Icons/ChevronIcon';

const selectWrapper = css`
     position: relative;
     user-select: none;
     width: 100%;
     font-size: 1rem;
`;

const groupStyle = css`
&:not(:last-of-type){
margin-bottom: .6rem;
}
`;

const customSelect = css`
     position: relative;
     display: flex;
     flex-direction: column;
     border-radius: 30px;
`;

const customSelectTrigger = css`
     position: relative;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: .8rem 1rem .8rem 1.5rem;
     background: #ffffff;
     cursor: pointer;
     border-radius: 30px;
     z-index: 3;
     line-height: 1.2;
`;

const customSelectTriggerOpen = css`
     z-index: 6;
     &:before{
     content: '';
     position: absolute;
     top: -10px;
     left: 0;
     background-color: white;
     width: 100%;
     height: 30px;
     z-index: -1;
     }
`;

const customOptions = css`
     position: absolute;
     display: block;
     bottom: 100%;
     left: 0;
     right: 0;
     background-color: white;
     transition: all 0.5s;
     opacity: 0;
     visibility: hidden;
     pointer-events: none;
     z-index: 2;
     border-radius: 10px;
     max-height: 370px;
     overflow: scroll;
     box-shadow: 0 0 10px rgba(0,0,0,0.1);
     @media (max-width: 840px) {
     max-height: 200px;
    }
`;
const customOption = css`
     position: relative;
     display: block;
     padding: 1rem 1rem 1rem 1.5rem;
     cursor: pointer;
     transition: all 0.5s;
     
     &:last-of-type{
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
     }
     &:hover {
     cursor: pointer;
     background-color: #b2b2b2;
     }
`;

const openStyle = css`
     opacity: 1;
     visibility: visible;
     pointer-events: all;
     z-index: 5;
`;

const selectedStyle = css`
    color: #ffffff;
     background-color: #305c91;
`;

const FontOption = styled.span`
font-family: ${props => props.font};
line-height: 1.1;
`;


function Select({open, setOpen, id, ...props}) {
    const defaultOption = props.options.find(option => option.value === props.value);
    const [selected, setSelected] = React.useState(defaultOption || {});


    function setOption(option) {
        setSelected(option);
        props.onChange(option.value || option.slug);
        setOpen(id);
    }

    function renderOptions() {
        return props.options.map(option => {
            if (option.font) {
                return <FontOption key={option.slug} onClick={() => setOption(option)}
                                   css={[customOption, selected.value === option.value && selectedStyle]}
                                   font={option.font}>{option.name}</FontOption>

            }
            return (
                <span key={option.slug} onClick={() => setOption(option)}
                      css={[customOption, selected.slug === option.slug && selectedStyle]}>{option.name}</span>
            )
        })
    }

    return (
        <>
            {open && (
                <div onClick={() => setOpen(id)}
                     css={[css`position: fixed; top: 0; right: 0; bottom: 0;
left: 0; z-index: 3;`]}/>
            )}
            {props.label && (
                <label css={[labelStyle]} htmlFor={props.name}>
                    {props.label}
                </label>
            )}
            <div id={props.name} css={[selectWrapper, props.grouped && groupStyle]}>
                <div css={[customSelect]}>
                    <div css={[customSelectTrigger, open && customSelectTriggerOpen]} onClick={() => setOpen(id)}>
                        {selected.font ?
                            <FontOption font={selected.font}>{selected.name}</FontOption>
                            :
                            <span>{selected.name}</span>
                        }
                        <ChevronIcon css={[css`transition: all .25s;`, !open && css`transform: rotateX(180deg);`]}
                                     width={16} height={16}/>
                    </div>
                    <div css={[customOptions, open && openStyle]}>
                        {renderOptions()}
                    </div>
                </div>
            </div>
        </>
    );
}

Select.propTypes = {
    name: propTypes.string.isRequired,
    label: propTypes.string,
    value: propTypes.string,
    placeholder: propTypes.string,
    onChange: propTypes.func.isRequired,
    options: propTypes.array
};

export default Select;
