import axios from 'axios';

export const addItemToCart = (data) => {
    return axios({
        method: 'POST',
        url: '/wp-json/cocart/v1/add-item',
        data
    }).then(res => {
        document.querySelector('body').dispatchEvent(new Event('wc_fragment_refresh'));
        document.querySelector('body').dispatchEvent(new Event('added_to_cart'));
        return res;
    }).catch(err=>{
        console.log(err)
    })
}
