import React from 'react';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import propTypes from 'prop-types';
import {labelStyle} from '../../common.styles';

const Input = styled.input`
&[type='text'],&[type='number'] {
height: unset;
box-shadow: unset;
transition: unset;
margin-bottom: unset;
padding: .8rem 1rem .8rem 1.5rem;
font-size: 1rem;
border-radius: 30px;
border: 0;
outline: none;
width: 100%;
box-sizing: border-box;
line-height: 1;
text-align: ${props=> props.align || 'left'};
}
`;

const groupStyle = css`
&:not(:last-of-type){
margin-bottom: .6rem;
}
`;

const textFieldWrapperStyle = css`
position: relative;
&:not(:last-of-type){
margin-bottom: 1rem;
}
`;

const suffixStyle = css`
position: absolute;
right: 2rem;
top: 50%;
transform: translateY(-50%);
`;

function TextInput({grouped, suffix, ...props}) {
    return (
        <>
            {props.label && (
                <label css={[labelStyle]} htmlFor={props.name}>
                    {props.label}
                </label>
            )}
            <div css={[textFieldWrapperStyle, grouped && groupStyle]}>
                <Input
                    align={props.align}
                    type={props.type}
                    {...props}
                    onChange={({target: {value}}) => props.onChange(value)}/>
                {suffix && <span css={[suffixStyle]}>{suffix}</span>}
            </div>
        </>
    );
}

TextInput.propTypes = {
    name: propTypes.string.isRequired,
    label: propTypes.string,
    value: propTypes.string.isRequired,
    type: propTypes.string,
    placeholder: propTypes.string,
    onChange: propTypes.func.isRequired,
    grouped: propTypes.bool
};

export default TextInput;
