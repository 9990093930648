

export const fontOptions = [
    {
        slug: 'timesnewroman',
        value: 'timesnewroman',
        name: 'Times New Roman',
        font: 'Times New Roman'
    },
    {
        slug: 'verdana',
        value: 'verdana',
        name: 'Verdana',
        font: 'Verdana'
    },
    {
        slug: 'avantgarde',
        value: 'avantgarde',
        name: 'AvantGarde',
        font: 'AvantGarde'
    },
    {
        slug: 'helvetica',
        value: 'helvetica',
        name: 'Helvetica',
        font: 'Helvetica'
    },
    {
        slug: 'garamond',
        value: 'garamond',
        name: 'Garamond',
        font: 'Garamond'
    },
    {
        slug: 'roboto',
        value: 'roboto',
        name: 'Roboto',
        font: 'Roboto'
    },
    {
        slug: 'couriernew',
        value: 'couriernew',
        name: 'Courier New',
        font: 'Courier New'
    },
    {
        slug: 'gillsans',
        value: 'gillsans',
        name: 'Gill Sans',
        font: 'Gill Sans'
    },
    {
        slug: 'centuryschoolbook',
        value: 'centuryschoolbook',
        name: 'Century Schoolbook',
        font: 'Century Schoolbook'
    },
    {
        slug: 'futura',
        value: 'futura',
        name: 'Futura',
        font: 'Futura'
    },
    {
        slug: 'bernhardtango',
        value: 'bernhardtango',
        name: 'Bernhard Tango',
        font: 'Bernhard Tango'
    }
];


export const fontSizeOptions = [
    {
        slug: 'xsmall',
        name: 'Pytteliten',
        value: '7'
    },
    {
        slug: 'small',
        name: 'Liten',
        value: '8'
    },
    {
        slug: 'medium',
        name: 'Mellan',
        value: '9'
    },
    {
        slug: 'large',
        name: 'Stor',
        value: '10'
    },
    {
        slug: 'xlarge',
        name: 'Större',
        value: '11'
    },
    {
        slug: 'xxlarge',
        name: 'Störst',
        value: '12'
    }
]
