import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './data.json'


function Spinner(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie options={defaultOptions} {...props}/>
    );
}

export default Spinner;
