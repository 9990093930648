import React from 'react';
import {CloseIcon} from '../Icons/CloseIcon';
import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/core';

const growSmall = keyframes`
0%{transform: scale(0.5);}
100%{transform: scale(1);}
`;

const growLarge = keyframes`
0%{
transform: scale(0.5) translate(-50%, -50%);

}
100%{
transform: scale(1) translate(-50%, -50%);
}
`;

const openingStyle = css`
animation: ${growLarge};
@media (max-width: 840px) {
animation: ${growSmall};
}
animation-duration: .5s;
animation-direction: normal;
animation-iteration-count: 1;
`;

const closingStyle = css`
animation: ${growLarge};
@media (max-width: 840px) {
animation: ${growSmall};
}
animation-duration: .5s;
animation-direction: reverse;
animation-iteration-count: 1;
`;


const ComponentWrapper = styled.div`
position: fixed;
top: 50%;
left: 50%;
width: ${props=> props.mini ? 'auto' : '100%'};
max-width: ${props=> props.mini ? '600px' : '1020px'};
transform-origin: 0 0;
transform: scale(1) translate(-50%, -50%);
box-sizing: border-box;
padding: ${props=> props.mini ? '2rem' : '2rem 1rem'};
margin: 0 auto;
z-index: 999999999;
@media (max-width: 840px) {
    ${props=> !props.mini ? `
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: unset;
    transform:unset;
    
    overflow: scroll;
    background-color: ${props => props.bgcolor};
    }
    @media (max-height: 770px) and (min-width: 840px) {
    max-width: unset;
    height: 100vh;
    ` : `
    width: 90%;
    `}
}

h2 {
margin: 0;
margin-bottom: 2rem;
}

&::before {
content: '';
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
width: 100%;
height: 100%;
background-color: ${props => props.bgcolor};
z-index: -1;
border-radius: 20px;
@media (min-width: 840px) {

box-shadow: 0 0 20px rgba(0,0,0,0.15);
}
}
${props => props.isClosing ? closingStyle : openingStyle}
`;

const CloseIconStyle = styled.div`
position: absolute; 
right: 1.5rem; 
top: 1rem;
z-index: 99999;
cursor: pointer;
@media (max-width: 840px) {
position: fixed;
}
`;
export default function ({children, settings, ...props}){
    const [isClosing, setIsClosing] = React.useState(false);

    function handleCloseModal() {
        setIsClosing(true);
        setTimeout(() => {
            props.setModalOpen();
        }, 0)
        setIsClosing(false);
    }


    return (
        <ComponentWrapper mini={props.mini} key={`modal-${settings.widgetid}`} id={`modal-${settings.widgetid}`} isClosing={isClosing} bgcolor={settings.bgcolor}>
            {!props.disableClose && (<CloseIconStyle><CloseIcon onClick={handleCloseModal}/></CloseIconStyle>)}
                {children}
        </ComponentWrapper>
    )
}
