import React from 'react';
import {css} from '@emotion/core';
import styled from '@emotion/styled';
import {getProductPadding} from '../../helpers';
import Button from '../Button';

const itemWrapper = css`
max-width: 50%;
box-sizing: border-box;
padding: 2rem;
display: flex;
flex-direction: column;
justify-content: space-between;
@media (max-width: 840px) {
max-width: 100%;
}
`;
const imageWrapper = css`
display: flex;
align-items: center;
`;
const previewImageStyle = css`
width: 100%;
`;
const TextWrapper = styled.div`
position: absolute;
  top: 0;
  right:0;
  bottom:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: ${props => props.fontSize}px;
`;

const PreviewText = styled.div`
font-family: ${props => props.font};
font-size: calc(${props => props.fontSize}em * 1.2) ;
text-align: center;
line-height: 1;
background-color: #020202;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
`;


const colorSelect = css`
display: flex;
position: absolute;
top: 0px;
left: 50%;
transform: translateX(-50%);
`;

const ColorBlob = styled.div`
height: 20px;
width: 20px;
background-color: ${props => props.color === 'gold' ? '#f8cb8b' : '#bcbcbc'};
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
margin: 0 .25rem;
transition: all .2s ease-in-out;
cursor: pointer;
&:hover {
transform: scale(1.1);
}
`;

const activeColor = css`
transform: scale(1.1);
`;

let arr = [];

function ProductListItem({item, selectedItem, setSelectedItem, rowOne, rowTwo, introText, fontSize, font, largestImageHeight, allImageHeights, setBuilderOpen, ...props}) {
    const [imageHeight, setImageHeight] = React.useState(160);
    const {height, width} = item;
    const fontHeight = (10 / (height * 10));
    const imageRef = React.useRef();
    const [maxHeight, setMaxHeight] = React.useState(200);
    const [color, setColor] = React.useState('gold');

    const colors = [...new Set(item.variations.map(v=>v.attributes.attribute_pa_color))].sort((a, b) => ('' + a).localeCompare(b));
    const selectedVariation = item.variations.find(v => v.attributes.attribute_pa_color === color) || {image: {full_src: ''}};
    const productImage = selectedVariation.image.full_src;

    React.useEffect(() => {
        if (imageRef.current) {
            setTimeout(() => {
                if ( imageRef.current.offsetHeight > 0) {
                    setImageHeight(imageRef.current.offsetHeight);
                    arr.push(imageRef.current.offsetHeight + 20);
                    if (Math.max(...arr) > maxHeight) {
                        setMaxHeight(Math.max(...arr));
                    }
                }
            }, 100)
        }
    }, [imageRef.current]);
    React.useEffect(()=>{},[imageHeight])
    React.useEffect(()=>{},[fontHeight])

    return (
        <div css={[itemWrapper]}>
            <div>
                <div css={css`  width: 100%; position:relative; text-align: left;box-sizing: border-box; padding: 0 ${getProductPadding(width)}rem; @media (max-width: 840px){ padding: 0 ${getProductPadding(width, 'small')}rem; display: flex; flex-direction: column; align-items: center;`}>
                    <div css={[imageWrapper, css`height: ${maxHeight}px;`]}>
                        <img ref={imageRef} css={[previewImageStyle]} src={productImage} alt="" onClick={() => {
                            setSelectedItem(item);
                            setTimeout(() => {
                                setBuilderOpen();
                            }, 50)

                        }}/>
                    </div>
                    <TextWrapper fontSize={imageHeight}>
                        <PreviewText fontSize={fontHeight} font='timesnewroman'>
                            <div>{rowOne || introText}</div>
                            <div>{rowTwo}</div>
                        </PreviewText>
                    </TextWrapper>

                </div>
                <div css={[css`position: relative;`]}>
                    <div css={[colorSelect]}>{colors.map(c=> <ColorBlob key={c} css={[color === c && activeColor]} color={c} onClick={()=>setColor(c)}/>)}</div>
                    <h2 css={[css`margin: 0; padding-top:20px;`]}>{item.name}</h2>
                    <div css={[css`color: #8b8b8b;margin-bottom:6px;`]}>{width*10} x {height*10}mm</div>
                    <p>{item.product_builder_description || item.short_description}</p>
                </div>
            </div>
            <div>
                <Button
                    label={props.settings.productlistbuttontext || 'Välj mig!'}
                    color={props.settings.productlistbuttoncolor || '#000'}
                    colorHover={props.settings.productlistbuttoncolor_hover || '#fff'}
                    textColor={props.settings.productlistbuttontextcolor || '#fff'}
                    textColorHover={props.settings.productlistbuttontextcolor_hover || '#000'}
                    width={200}
                    onClick={() => {
                    setSelectedItem(item);
                    setTimeout(() => {
                        setBuilderOpen();
                    }, 50)

                }}/>
            </div>
        </div>
    );
}


export default ProductListItem;
