import React from 'react';
import axios from 'axios';
import {css} from '@emotion/core';
import Builder from './components/Builder';
import Button from './components/Button';
import BodyEnd from './components/BodyEnd';
import ProductList from './components/ProductList/ProductList';
import TextInput from './components/Input/Text';
import Modal from './components/Modal/Modal';
import {addItemToCart} from './actions/actions';

const url = process.env.PUBLIC_URL || 'http://dinskylt.docker';

function App({settings, target}) {
    const [items, setItems] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [rowOne, setRowOne] = React.useState(window.app_rowOne || 'Namn');
    const [rowTwo, setRowTwo] = React.useState(window.app_rowTwo || '');
    const [loading, setLoading] = React.useState(true);
    const [builderOpen, setBuilderOpen] = React.useState(false);
    const [addedToCart, setAddedToCart] = React.useState(null);
    const [upsellModalOpen, setUpsellModalOpen] = React.useState('');

    React.useEffect(() => {
        getItems()
    }, []);

    React.useEffect(() => {
        window.app_rowOne = rowOne;
        window.app_rowTwo = rowTwo;
    }, [rowOne, rowTwo])

    function getItems() {
        axios({
            method: 'GET',
            //url: `http://dinskylt.docker/ajax-api/products/${settings.productcategoryid}`,
            url: `/ajax-api/products/${settings.productcategoryid}/`
        }).then(res => {
            setLoading(false);
            //setItems(res.data);
            setItems(res.data.data.sort((a, b) => ('' + a.stock_status).localeCompare(b.stock_status))
                .sort((a, b) => ('' + b.name).localeCompare(a.name)));
            //setSelectedItem(settings.defaultproductid  ? res.data.data.find(p=>p.id.toString() === settings.defaultproductid.toString()) : res.data[0]);
            setSelectedItem(settings.defaultproductid ? res.data.data.find(p => p.id.toString() === settings.defaultproductid.toString()) : res.data.data[0]);
        });
        //setItems(baseItems);
        //setSelectedItem(baseItems.find(item=>item.id === settings.defaultproductid) || baseItems[0])
        setLoading(false)
    }

    function handleSetBuilderOpen() {
        setBuilderOpen(!builderOpen);
    }

    function handleSetSelectedItem(id) {
        const itemToSet = items.find(item => item.id === id);
        setSelectedItem(itemToSet);
    }

    function handleSetUpsellModal(id) {
        setUpsellModalOpen(id)
    }

    function addUpsellToCart() {
        const data = {
            product_id: settings.upsellproductid.toString() || '2426',
            quantity: 1
        }
        addItemToCart(data).then(() => {
            setUpsellModalOpen('');
        })
    }


    if (loading) {
        return '';
    }
    const props = {
        items,
        selectedItem,
        setSelectedItem,
        handleSetSelectedItem,
        rowOne,
        setRowOne,
        rowTwo,
        setRowTwo,
        introText: rowOne,
        loading,
        settings,
        setBuilderOpen: handleSetBuilderOpen,
        setUpsellModal: handleSetUpsellModal
    };

    function renderUpsellModal() {
        return (
            <BodyEnd>
                <Modal mini disableClose {...props} setModalOpen={() => setUpsellModalOpen(!upsellModalOpen)}>
                    <h4 css={[css`margin: 0 0 20px; text-align: center;`]}>Din skylt har lagts ner i varukorgen!</h4>
                    <h2 css={[css`margin: 0; text-align: center;`]}>Trött på reklam?</h2>

                    <p css={[css`text-align: center;`]}>Värna om dig själv och miljön, lägg till en ingen reklam tack-skylt för endast <b>69kr!</b></p>
                    <div css={[css` img { width: 100%;} padding: 20px 60px; margin-bottom: 30px;`]}>
                        <img
                            src={settings.upsellproductimage || 'https://dinskylt.se/wp-content/uploads/2020/05/Ingen_reklam_eller_gratistidningar_tack_min.png'}
                            alt="Ingen reklam tack"/>
                    </div>
                    <div css={[css`max-width: 300px; margin: 0 auto;`]}>
                        <Button onClick={addUpsellToCart} variant="black" label="Ja tack, gärna!"/>
                        <Button onClick={() => handleSetUpsellModal('')} label="Nej Tack"/>
                    </div>
                </Modal>
            </BodyEnd>
        )
    }


    if (settings.productlist) {
        return (
            <div>
                <div css={css`text-align: center;`}>
                    <h2>Vad skall det stå på din dörrskylt?</h2>
                    <div css={css`max-width: 300px; margin: 0 auto;`}>
                        <TextInput align="center" placeholder={rowOne} onChange={setRowOne} name="init-name"
                                   type="text"/>
                    </div>
                </div>
                <ProductList {...props}/>
                {builderOpen && (
                    <BodyEnd>
                        <Modal
                            setModalOpen={handleSetBuilderOpen} {...props}><Builder {...props}/></Modal></BodyEnd>
                )}
                {upsellModalOpen === settings.widgetid && renderUpsellModal()}
            </div>
        )
    }
    return (
        <>
            <Button label={settings.buttonlabel || 'Designa din skylt nu'} variant={settings.buttonvariant || 'white'}
                    height={settings.buttonheight} width={settings.buttonwidth}
                    color={settings.btncolor}
                    colorHover={settings.btnhoverbgcolor}
                    textColor={settings.btntextcolor}
                    textColorHover={settings.btnhovertextcolor}
                    onClick={() => setBuilderOpen(!builderOpen)}/>
            {builderOpen &&
            <BodyEnd><Modal setModalOpen={handleSetBuilderOpen} {...props}><Builder {...props}/></Modal></BodyEnd>}
            {upsellModalOpen === settings.widgetid && renderUpsellModal()}

        </>
    )
}

export default App;

