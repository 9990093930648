import React from 'react';
import {css} from '@emotion/core';
import ProductListItem from './ProductListItem';

const listWrapper = css`
width: 100%;
display: flex;
flex-wrap: wrap;
box-sizing: border-box;
align-items: stretch;
@media (max-width: 840px) {
flex-direction: column;
}
`;


function ProductList({items, ...props}) {
    const [imagesHeights, setImagesHeights] = React.useState([]);

    function allImageHeights(height) {
        setImagesHeights([...imagesHeights, height]);
    }

    return (
        <div key={`productlist-${props.settings.widgetid}`} id={`productlist-${props.settings.widgetid}`} css={[listWrapper]}>
            {items.map(item =>
                <ProductListItem
                    item={item}
                    setSelectedItem={props.setSelectedItem}
                    setBuilderOpen={props.setBuilderOpen}
                    largestImageHeight={Math.max(...imagesHeights)}
                    allImageHeights={allImageHeights}
                    {...props}
                />
            )}
        </div>
    );
}


export default ProductList;
