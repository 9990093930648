import React from 'react';
import {css, keyframes} from '@emotion/core';
import propTypes from 'prop-types';
import {CheckIcon} from '../Icons/CheckIcon';

const scaleIn = keyframes`
0%{transform: scale(0) translate(-50%, -50%);}
70%{transform: scale(1.2) translate(-50%, -50%);}
100%{transform: scale(1) translate(-50%, -50%);}
`;
const checkboxStyle = css`
background: #ffffff;
display: inline-block;
position: relative;
box-sizing: content-box;
padding: .5rem;
border-radius: 50%;
width: 28px;
height: 28px;
`;
const checkStyle = css`
position: absolute;
left: 50%;
top: 50%;
transform: scale(0) translate(-50%, -50%);
`;
const checkCheckedStyle = css`
animation: ${scaleIn} .1s forwards;
`;

function Checkbox({checked, onChange, label}) {
    return (
        <>
            {label && (
                <label css={css`display: block; margin-bottom: 3px;`}>
                    {label}
                </label>
            )}
            <div css={[checkboxStyle]} onClick={onChange}>
                <CheckIcon css={[checkStyle, checked && checkCheckedStyle]} width={20} height={20}/>
            </div>
        </>
    );
}

Checkbox.propTypes = {
    checked: propTypes.bool,
    onChange: propTypes.func
};

export default Checkbox;
