import React from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import {css, keyframes} from '@emotion/core';
import TextInput from './Input/Text';
import Select from './Input/Select';
import Button from './Button';
import Spinner from './Spinner/Spinner';
import ProductPreview from './Preview/Preview';
import styled from '@emotion/styled';
import Checkbox from './Input/Checkbox';
import ChevronIcon from './Icons/ChevronIcon';
import {fontOptions, fontSizeOptions} from '../items/fontOptions';
import html2canvas from 'html2canvas';
import {addItemToCart} from '../actions/actions';

const hideMobile = css`
@media (max-width: 840px) {
display: none;
}
`;

const hideDesktop = css`
display: none;
@media (max-width: 840px) {
display: block;
}
`;

const wrapper = css`
display: flex;
align-items: flex-start;
@media (max-width: 840px) {
flex-direction: column;
background-color: rgb(242, 242, 242);
}
@media (max-height: 770px) and (min-width: 840px) {
height:100vh;
width: 100vw;
overflow-y: scroll;
padding-bottom: 1rem;
}
`;

const sectionTitle = css`
text-align: center;
@media (max-width: 840px) {
display: none;
}
`;
const fieldsWrapper = css`
width: 100%;
display: flex;
margin-top: 1rem;
@media (max-width: 840px) {
margin-top: 0;
width: 100%;
flex-direction: column;
padding: 0 1rem;
box-sizing: border-box;
}
form {
height: 100%;
display: flex;
justify-content: space-between;
}
`;

const secondColStyle = css`
width: 100%; 
margin-left: .5rem;
@media (max-width: 840px) {
margin: 0;
}
`

const Section = styled.div`
margin-top: ${props => props.marginTop}rem;
`;

const SelectProductWrapper = styled.div`
margin-top: ${props => props.marginTop || 0}px;
`;

const selectProduct = css`
width: 20%;
@media (max-width: 840px) {
width: 100%;
}
`;


const priceStyle = css`
font-weight: bold;
font-size: 2rem;
`;

const previewWrapper = css`
width: 50%;
padding: 0 1rem ;
display: flex;
flex-direction: column;
justify-content: center;

@media (max-width: 840px) {
width: 100%;
padding: 0;
margin-bottom: 1rem;
}
`;
const productPreviewWrapper = css`
width: 100%;
background-color: white;
border-radius: 10px;
@media (max-width: 840px) {
position: fixed;
top: 0;
left: 0;
z-index: 999;
border-radius: 0;
}
`;

const productListWrapper = css`
width:100%;
box-sizing: border-box;
@media (max-width: 840px) {
    position: relative;
    width: 100vw;

    padding: 0 1rem;
}
`;

const scrollWrapper = css`
@media (max-width: 840px) {

position: relative;
width: 100%;
    overflow-x: scroll;
        height: 140px;
        }

`;

const scrollChevronStyle = css`
@media (max-width: 840px) {
pointer-events: none;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;

display: flex;
justify-content: space-between;
align-items: center;
z-index: 1;
}
`;

const productList = css`
display: flex;
flex-direction: column;
justify-content: space-around;
margin: 2rem 0 0;
position: relative;

@media (max-width: 840px) {
position: absolute;
flex-direction: row;
height: 100px;
margin-top: .5rem;
}
`;

const productIcon = css`
width: 100%;
text-align: center;
padding: 1.5rem;
color: #8b8b8b;
position: relative;
box-sizing: border-box;
margin-bottom: .5rem;
@media (max-width: 840px) {
    width: 100px;
    text-align: center;
    padding: 1.5rem;
    color: rgb(139, 139, 139);
    position: relative;
    box-sizing: content-box;
   
}


img {
width: 80%;
@media (max-width: 840px) {
width: 100px;
}
}
`;

const mediumPlate = css`
img {
width: 90%;
}
`;
const largePlate = css`
img {
width: 100%;
}
`;

const SelectedBg = styled.div`
background-color:#fff;
position: absolute;
left: 0;
top: ${props => props.position}px;
height: ${props => props.height}px;
width: ${props => props.width}px;
border-radius: 10px;
transition: all .2s ease-out;
@media (max-width: 840px) {
top: 0;
left: ${props => props.position}px;
}

`;

const footerWrapper = css`
@media (max-width: 840px) {
width: 100%;
box-sizing: border-box;
padding: 0 1rem 1rem;
}`;

const colorSelect = css`
display: flex;
width: 100%;
justify-content: center;
margin-top: .5rem;
`;

const ColorBlob = styled.div`
height: 20px;
width: 20px;
background-color: ${props => props.color === 'gold' ? '#f8cb8b' : '#bcbcbc'};
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
margin: 0 .25rem;
transition: all .2s ease-in-out;
cursor: pointer;
&:hover {
transform: scale(1.1);
}
`;
const activeColor = css`
transform: scale(1.1);
`;

function Builder({items, selectedItem, setSelectedItem, loading, rowOne, setRowOne, rowTwo, setRowTwo, settings, introText, ...props}) {
    const [font, setFont] = React.useState('timesnewroman');
    const [fontSize, setFontSize] = React.useState('10');
    const [screws, setScrews] = React.useState('y');
    const [color, setColor] = React.useState('gold');
    const [selectedPosition, setSelectedPosition] = React.useState();
    const [selectedDimensions, setSelectedDimensions] = React.useState({width: 0, height: 0});
    const selectedRef = React.useRef(null);
    const previewWrapperRef = React.useRef(null);
    const scrollRef = React.useRef(null);
    const [previewWrapperHeight, setPreviewWrapperHeight] = React.useState()
    const [loadingCart, setLoadingCart] = React.useState(false)
    const [openSelect, setOpenSelect] = React.useState('');

    const selectedVariation = selectedItem.variations && selectedItem.variations.find(v => v.attributes.attribute_pa_color === color && v.attributes.attribute_pa_holes === screws) || {image: {full_src: ''}};
    const isMobile = window.innerWidth < 840;
    const isWeirdScreen = window.innerHeight < 770;
    const productImage = selectedVariation.image.full_src;
    const isAvailable = selectedVariation.attributes.attribute_pa_available === 'y';

    const state = {selectedItem, rowOne, rowTwo, screws, font, fontSize, fontOptions, image: productImage};


    const activeItemHoles = [...new Set(selectedItem.variations.map(v => v.attributes.attribute_pa_holes))];

    async function addToCart() {
        setLoadingCart(true);

        const data = {
            'product_id': `${selectedVariation.variation_id}`,
            'quantity': 1,
            'cart_item_data': {
                'row_1': rowOne || '',
                'row_2': rowTwo || '',
                'font_size': fontSize,
                'font': fontOptions.find(f => f.slug === font).name,
                'screws': screws
            }
        }
        await addItemToCart(data).then(res => {
            setLoadingCart(false);
            props.setBuilderOpen();
            props.setUpsellModal(settings.widgetid);
        })
    }

    async function handleSubmit() {
        /*        await html2canvas(document.querySelector(`.image-${selectedItem.id}`)).then(function (canvas) {
                        const imageData = canvas.toDataURL('image/png');

                        axios({
                            method: 'POST',
                            url: '/wp-admin/admin-ajax.php',
                            dataType: 'json',
                            data: {
                                action: 'save_customer_image',
                                image_url: imageData
                            }
                        })
                    }
                )*/

        addToCart();
    }

    function handleOpenSubmit(id) {
        const isSame = openSelect === id;
        setOpenSelect(isSame ? '' : id);
    }

    React.useEffect(() => {
        const ref = selectedRef.current;
        if (ref) {
            setTimeout(() => {
                setSelectedPosition(!isMobile ? ref.offsetTop : ref.offsetLeft)
            }, 50)
        }
    }, [selectedItem])

    React.useEffect(() => {
        const ref = selectedRef.current;
        if (ref) {
            setTimeout(() => {
                setSelectedDimensions({
                    width: ref.offsetWidth,
                    height: isMobile ? ref.offsetHeight + 16 : ref.offsetHeight - 8
                });
            }, 50)
        }
    }, [selectedRef.current])

    React.useEffect(() => {
        const ref = previewWrapperRef.current;
        if (ref) {
            setPreviewWrapperHeight(ref.offsetHeight + 4);
        }
    }, [previewWrapperRef.current])

    function renderItems() {
        return items
            .map(item => {
                const itemNoHoles = [...new Set(item.variations.map(v => v.attributes.attribute_pa_holes))].indexOf('n') > -1;
                const colors = [...new Set(item.variations.map(v => v.attributes.attribute_pa_holes.indexOf(screws)>-1 ? v.attributes.attribute_pa_color : null))].filter(v=>!!v);
                const hasColor = !!colors.length && colors.indexOf(color)>-1;

                return (
                    <div key={item.id}
                         css={[productIcon, item.width > 13 && item.width < 16 ? mediumPlate : item.width > 16 ? largePlate : null]}
                         onClick={() => {
                             if (screws ==='n' && !itemNoHoles){
                                 setScrews('y');
                             }
                             if (!hasColor){
                                 setColor('gold')
                             }
                             setSelectedItem(item)
                         }}
                         ref={item.id === selectedItem.id ? selectedRef : null}
                    >
                        <img src={screws === 'n' && itemNoHoles ? item.product_icon_holeless : item.product_icon}
                             alt=""/>
                        <span>{item.product_builder_title || item.name}</span>
                    </div>
                )
            })
    }

    return (
        <div key={`builder-${settings.widgetid}`} id={`builder-${settings.widgetid}`} css={[wrapper]}>
            {!isMobile && (
                <div css={[selectProduct, hideMobile]}>
                    <h2 css={[sectionTitle]}>Välj din skylt</h2>
                    <div css={[productListWrapper]}>
                        <div css={[productList]}>
                            <SelectedBg width={selectedDimensions.width} height={selectedDimensions.height}
                                        position={selectedPosition}/>
                            {!!items.length && renderItems()}
                        </div>
                    </div>
                </div>
            )}
            <div css={[previewWrapper]}>
                {loading ? (
                    <div css={css`width:400px;`}>
                        <Spinner/>
                    </div>
                ) : (
                    <>
                        <h2 css={[sectionTitle]}>Designa och köp din skylt</h2>
                        <div css={[productPreviewWrapper]} ref={previewWrapperRef}>
                            {!!selectedItem.id &&
                            <ProductPreview introText={introText} isMobile={isMobile} isWeirdScreen={isWeirdScreen}
                                            ratio={40} state={state}
                                            color={color}
                                            setColor={setColor}
                                            screws={screws}
                            />}


                        </div>
                        {isMobile && (
                            <SelectProductWrapper marginTop={previewWrapperHeight}
                                                  css={[selectProduct, hideDesktop]}>
                                <h2 css={[sectionTitle]}>Välj din skylt</h2>
                                <div css={[productListWrapper]}>
                                    <div css={[hideDesktop, scrollChevronStyle]}>
                                        <div><ChevronIcon css={css`transform: rotateZ(90deg); fill: #a0a0a0;`}/>
                                        </div>
                                        <div><ChevronIcon css={css`transform: rotateZ(-90deg); fill: #a0a0a0;`}/>
                                        </div>
                                    </div>
                                    <div css={[scrollWrapper]} ref={scrollRef}>
                                        <div css={[productList]}>
                                            <SelectedBg width={selectedDimensions.width}
                                                        height={selectedDimensions.height}
                                                        position={selectedPosition}/>
                                            {!!items.length && renderItems()}
                                        </div>
                                    </div>
                                </div>
                            </SelectProductWrapper>
                        )}
                        <div css={[fieldsWrapper]}>
                            <div css={css`width: 100%; margin-right: .5rem;margin-bottom: .6rem;`}>
                                <TextInput type="text" name="row1" label="Första raden"
                                           onChange={setRowOne}
                                           placeholder={rowOne} grouped/>
                                <TextInput type="text" name="row2" value={rowTwo} onChange={setRowTwo}
                                           label="Andra raden"
                                           grouped/>
                            </div>

                            <div css={[secondColStyle]}>
                                <Select
                                    label="Välj typsnitt"
                                    name="fontSelect"
                                    options={fontOptions}
                                    value={font}
                                    onChange={setFont}
                                    grouped
                                    id="font"
                                    open={openSelect === 'font'}
                                    setOpen={handleOpenSubmit}
                                />
                                <div css={css`display: flex;`}>
                                    <div css={css`width: 100%;`}>
                                        <Select
                                            label="Välj textstorlek"
                                            name="fontSizeSelect"
                                            options={fontSizeOptions}
                                            value={fontSize}
                                            onChange={setFontSize}
                                            grouped
                                            id="size"
                                            open={openSelect === 'size'}
                                            setOpen={handleOpenSubmit}
                                        />
                                    </div>
                                    <div css={css`margin-left: 1rem; text-align: right;`}>
                                        {(activeItemHoles.indexOf('n') > -1) && (
                                            <Checkbox label="Skruvhål?" checked={screws === 'y'}
                                                      onChange={() => setScrews(screws === 'y' ? 'n' : 'y')}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div css={[hideDesktop, footerWrapper]}>
                <Section css={[css`display: flex; justify-content: space-between; align-items: center;`]}>
                    <div>
                        {screws === 'y' ? (
                                <div>Skruvar ingår</div>
                            ) :
                            (
                                <div>Fästkuddar ingår</div>
                            )}
                        <div>Fri frakt</div>
                        <div>Leverans inom 5 dagar</div>
                    </div>
                    <div css={[priceStyle]}>{selectedItem.price || '0'}kr</div>
                </Section>
                {!isAvailable && (
                    <Section>
                        <div css={[css`font-size: .8rem;`]}>Denna produkt är tyvärr inte tillgänglig för tillfället
                            :(
                        </div>
                        <div css={[css`font-size: .8rem;`]}>Kontakta oss i chatten eller på <a
                            href="mailto:info@dinskylt.se">info@dinskylt.se</a> för mer information
                        </div>
                    </Section>
                )}
                <Section marginTop="1">
                    <Button className="dinskylt_add_to_cart" disabled={!isAvailable}
                            label={isAvailable ? 'Lägg i varukorgen' : 'Slut :('} variant="black"
                            onClick={handleSubmit}/>
                </Section>
            </div>
            <div css={[hideMobile, css`width: 25%; height:100%; color: black;`]}>
                <div css={css`padding-top: 4rem;`}>
                    <h2>{selectedItem.product_builder_title || selectedItem.name}</h2>
                    <p>
                        {selectedItem.product_builder_description || selectedItem.short_description}
                    </p>
                </div>
                <div>
                    <Section css={[{/*alignRight*/}]}>
                        {screws === 'y' ? (
                                <div>Skruvar ingår</div>
                            ) :
                            (
                                <div>Fästkuddar ingår</div>
                            )}
                        <div>Fri frakt</div>
                        <div>Leverans inom 5 dagar</div>
                        <div css={[priceStyle]}>{selectedItem.price || '0'}kr</div>
                    </Section>
                    {!isAvailable && (
                        <Section>
                            <div css={[css`font-size: .8rem;`]}>Denna produkt är tyvärr inte tillgänglig för tillfället
                                :(
                            </div>
                            <div css={[css`font-size: .8rem;`]}>Kontakta oss i chatten eller på <a
                                href="mailto:info@dinskylt.se">info@dinskylt.se</a> för mer information
                            </div>
                        </Section>
                    )}
                    <Section marginTop="1">
                        <Button className="dinskylt_add_to_cart" disabled={!isAvailable}
                                label={isAvailable ? 'Lägg i varukorgen' : 'Slut :('} variant="black"
                                onClick={handleSubmit}/>
                    </Section>
                </div>
            </div>
            {/*    <div
                css={[css`background-color: white;display:flex; justify-content: center;position: fixed; top: 0;right: 0;bottom: 0;left: 0; z-index: 99999999999999;`]}>
                <img src={doogie} alt=""/>
                <div css={[css`position: absolute;bottom: 2rem;z-index: 1; text-align: center; background-color: white; padding: 1rem 3rem;`]}>Dogge lägger din skylt i varukorgen :)</div>
            </div>*/}
        </div>
    );
}

Builder.propTypes = {
    items: propTypes.array,
    selectedItem: propTypes.object,
    setSelectedItem: propTypes.func,
    loading: propTypes.bool,
    rowOne: propTypes.string,
    rowTwo: propTypes.string
};

export default Builder;
