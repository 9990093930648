import React from 'react';
import {css} from '@emotion/core';
import propTypes from 'prop-types';

const buttonStyle = css`
width: 100%;
font-size: 1rem;
font-weight: bold;
padding: .8rem 1rem .8rem 1rem;
border: 0;
border-radius: 30px;
outline: 0;
cursor:pointer;
margin: 0;
line-height: 1.1;
transition: all .2s ease-in-out;
&[type= "submit"]{
background-color: black;
color: white;
}

&:hover {
background-color: white;
color: black;
}
`;

const blackButton = css`
background-color: black;
color: white;
`;
const whiteButton = css`
background-color: white;
color: black;
&:hover{
background-color: black;
color: white;
}
`;

function Button(props) {

    const getVariant = (variant) => {
        if (variant === 'black') {
            return blackButton;
        }
        if (variant === 'white') {
            return whiteButton;
        }
        return null;
    }
    return (
        <button
            css={[
                buttonStyle,
                props.variant && getVariant(props.variant),
                props.width && css`max-width: ${props.width}px;`,
                props.height && css`height: ${props.height}px; border-radius: ${props.height}px;`,
                props.color && css`background-color: ${props.color};`,
                props.colorHover && css`&:hover{background-color: ${props.colorHover};}`,
                props.textColor && css`color: ${props.textColor};`,
                props.textColorHover && css`&:hover{color: ${props.textColorHover};}`,
            ]}
            type={props.type} {...props}>
            {props.label}
        </button>
    );
}

Button.propTypes = {
    label: propTypes.string.isRequired,
    type: propTypes.string,
    onClick: propTypes.func
};

export default Button;
