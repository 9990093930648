import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const targets = document.getElementsByClassName('productbuilder-root');

Array.prototype.forEach.call(targets, target => {
    const id = target.dataset.id;
    const settings = window.pb_settings ? window.pb_settings[id] : {
        bgcolor: '#f2f2f2',
        productcategoryid: 4
    };
    ReactDOM.render(<App target={target} settings={settings} />, target)
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
