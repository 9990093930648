import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import propTypes from 'prop-types';
import {getProductPadding} from '../../helpers';

const previewWrapper = css`
position: relative;
width: 100%;
min-height: 320px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

@media (max-width: 840px) {
min-height: unset;
height: 250px;
padding: 1rem 0;
}
`;

const Preview = styled.div`
  width: 100%;
  padding-top: ${props => props.ratio}%;
  position:relative
`;

const previewImageWrapperStyle = css`
  position: absolute;
  top: 0;
  right:0;
  bottom:0;
  left:0;
  transition: all .3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items:center;
`;

const previewImageStyle = css`
width: 100%;
`;
const TextWrapper = styled.div`
position: absolute;
  top: 0;
  right:0;
  bottom:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: ${props => props.fontSize}px;
`;

const PreviewText = styled.div`
font-family: ${props => props.font};
font-size: calc(${props => props.fontSize}em * 1.2) ;
text-align: center;
line-height: 1;
background-color: #020202;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
`;

const itemSize = css`
position: absolute;
left: 1rem;
bottom: 1rem;
color: #8b8b8b
`;

const colorSelect = css`
position: absolute;
bottom: 1rem;
left: 50%;
transform: translate(-50%, .5rem);
display: flex;
width: 100%;
justify-content: center;
`;

const ColorBlob = styled.div`
height: 30px;
width: 30px;
background-color: ${props => props.color === 'gold' ? '#f8cb8b' : '#bcbcbc'};
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
margin: 0 .25rem;
transition: all .2s ease-in-out;
cursor: pointer;
&:hover {
transform: scale(1.1);
}
`;
const activeColor = css`
transform: scale(1.1);
`;


function ProductPreview({state: {selectedItem, rowOne, rowTwo, font, fontSize, fontOptions, image}, introText, color, setColor, screws, isMobile, isWeirdScreen}) {
    const [imageHeight, setImageHeight] = React.useState();
    const {height, width} = selectedItem;
    const fontHeight = (parseInt(fontSize) / (height * 10));
    const imageRef = React.useRef();
    const colors = [...new Set(selectedItem.variations.map(v => v.attributes.attribute_pa_holes.indexOf(screws)>-1 ? v.attributes.attribute_pa_color : null))].filter(v=>!!v).sort((a, b) => ('' + a).localeCompare(b));

    React.useEffect(() => {
        if (imageRef.current) {
            setTimeout(() => {
                setImageHeight(imageRef.current.offsetHeight)
            }, 100)
        }
    }, [height, width]);
    React.useEffect(() => {
    }, [imageHeight])
    React.useEffect(() => {
    }, [fontHeight])
    return (
        <div css={[previewWrapper]}>
            {!isMobile &&
            <div css={[colorSelect]}>{colors.map(c => {
                return (
                    <ColorBlob key={c} css={[color === c && activeColor]}
                               color={c}
                               onClick={() => setColor(c)}/>
                )
            })}</div>}
            <Preview ratio={(height / width) * 100}>
                <div
                    css={[previewImageWrapperStyle, css`padding: 0 ${getProductPadding(width, isMobile ? 'small' : isWeirdScreen ? 'weird' : null, true)}rem; @media (max-width: 840px){ padding: 0 ${getProductPadding(width, 'small')}rem`]}>
                    <img ref={imageRef} className={`image-${selectedItem.id}`} css={[previewImageStyle]} src={image}
                         alt=""/>
                    <TextWrapper fontSize={imageHeight}>
                        <PreviewText fontSize={fontHeight} font={fontOptions.find(f => f.slug === font).name}>
                            <div>{rowOne || introText}</div>
                            <div>{rowTwo}</div>
                        </PreviewText>
                    </TextWrapper>
                </div>
            </Preview>
            <div css={[itemSize]}>Mått: {`${width * 10} x ${height * 10}mm`}</div>
            {isMobile &&
            <div css={[colorSelect]}>{colors.map(c => <ColorBlob key={c} css={[color === c && activeColor]} color={c}
                                                                 onClick={() => setColor(c)}/>)}</div>}
        </div>
    );
}

ProductPreview.propTypes = {
    ratio: propTypes.number
};

export default ProductPreview;
