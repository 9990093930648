import {css} from '@emotion/core';

export const labelStyle = css`
display: block;
margin-bottom: .2rem;
`;

export const invisibleStyle = css`
visibility: hidden;
`;

export const mainFontStyle = css`

`;
